<script setup lang="ts">
import opinions, { type Opinion } from '~/src/opinions.ts';

const props = withDefaults(defineProps<{
    word: string;
    pronunciation?: string | null;
    opinion: string;
    link?: unknown;
    escape?: boolean;
    customOpinions?: Record<string, Opinion & { description: string }>;
    markdown?: boolean;
}>(), {
    escape: true,
    customOpinions: () => ({}),
});

const { $translator: translator } = useNuxtApp();

const op = computed((): (Opinion & { description: string }) | null => {
    if (Object.hasOwn(opinions, props.opinion)) {
        return {
            ...opinions[props.opinion],
            description: translator.translate(`profile.opinion.${props.opinion}`),
        };
    }

    for (const op of Object.values(props.customOpinions)) {
        if (op.icon === props.opinion) {
            return op;
        }
    }

    return null;
});
</script>

<template>
    <span v-if="op" :class="[op.style, `colour-${op.colour}`]">
        <Tooltip :text="op.description">
            <Icon :v="op.icon" />
        </Tooltip>
        <nuxt-link v-if="link" :to="link" :class="`colour-${op.colour || 'default'}`"><Spelling :escape="escape" :text="word" /></nuxt-link>
        <span v-else><Spelling :escape="escape" :markdown="markdown" :text="word" /></span>
        <Pronunciation v-if="pronunciation" :pronunciation="pronunciation" text />
    </span>
</template>

<style lang="scss" scoped>
@import "assets/variables";

.small .fa-fw {
    width: #{$fa-fw-width / $small-font-size}em;
}
</style>
